

































import BazaarApi from "@/api/bazaar.api";
import { List } from "vant";
import Empty from "@/components/EmptyBox.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
    components:{
        List,
        Empty
    }
})
export default class SupplyDetail extends Vue {
  MineLogData: any = [];
  finished = false;
  loading = false;
  page = 1
  page_size = 10
  async created() {
    this.MineLogData = await BazaarApi.getMineLog();
    this.onLoad()
    if(this.MineLogData.length<10){
      this.finished = true;
    }
  }
  async onLoad() {
    this.page++;
    let result = await BazaarApi.getMineLog(this.page, this.page_size);
    result.forEach((item:any) => {
      this.MineLogData.push(item);
    });
    this.loading = false;
    if (result.length < 10) {
      this.finished = true;
    }
  }
}
